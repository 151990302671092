import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'
import BuildingSearch from 'components/utility/building_search'
import QuoteTabsProgress from '../QuoteTabsProgress'
import {
  INSURANCE_QUOTE_STEPS,
  resetStateWithProperty as resetStateWithPropertyAction,
  setStep as setStepAction,
  initiateQuote as initiateQuoteAction,
  initiateQuoteRequest as initiateQuoteRequestAction,
  setFormData as setFormDataAction,
  setStartingQuote as setStartingQuoteAction,
  getBuildingRequirements as getBuildingRequirementsAction,
  selectInsuranceQuoteInterstitialState,
  setInsuranceType as setInsuranceTypeAction,
} from '../../store/slice'
import PolicyForm from './PolicyForm/PolicyForm'
import AboutYouForm from './AboutYouForm/AboutYouForm'
import FinalizeQuoteForm from './FinalizeQuoteForm/FinalizeQuoteForm'

const tabs = {
  [INSURANCE_QUOTE_STEPS.property_page]: 'property',
  [INSURANCE_QUOTE_STEPS.policy_page]: 'policy',
  [INSURANCE_QUOTE_STEPS.about_you_page]: 'about-you',
}

const SUGGESTIONS_URL = '/insurance/buildings/lookup_buildings'

const asDollars = (amount) => parseFloat(amount || 0) / 100
const valuesLargerThan = (needle, list) => (needle ? (list || []).filter((item) => needle <= item || 0) : list || [])

// TODO: Refactor
const getStartingQuote = (building, insuranceType) => {
  const startingQuotes = (() => {
    if (isEmpty(building?.insurance_building_settings)) return {}

    const settings = {}

    building.insurance_building_settings.forEach((setting) => {
      if (setting['starting_quote_relevant?']) {
        settings[setting.unit_type] = setting.serialized_starting_quote
      }
    })

    return settings
  })()

  return startingQuotes[insuranceType]
}

function Main({ step, loadingVisible }) {
  const dispatch = useDispatch()
  const {
    formData,
    building,
    building_structure_id,
    insuranceConstants,
    package_id,
    insuranceType,
    minimums,
    quote,
    requirements,
    startingQuote,
  } = useSelector(selectInsuranceQuoteInterstitialState)

  const onBuildingSelect = useCallback(
    (b) => {
      if (b?.id) {
        const currentInsuranceType = insuranceType

        dispatch(resetStateWithPropertyAction(b))
        dispatch(setInsuranceTypeAction(currentInsuranceType))
        dispatch(setStepAction(INSURANCE_QUOTE_STEPS.policy_page))
        dispatch(getBuildingRequirementsAction(building.id))

        const startingQuote = getStartingQuote(b, currentInsuranceType)

        if (startingQuote) {
          dispatch(setStartingQuoteAction(startingQuote))
        }
        // else {
        //   dispatch(
        //     initiateQuoteAction({
        //       rollbackStep: INSURANCE_QUOTE_STEPS.property_page,
        //       nextStep: INSURANCE_QUOTE_STEPS.policy_page,
        //     })
        //   )
        // }
      }
    },
    [dispatch]
  )

  // ---------- TODO: Refactor
  const renderPropertyMain = () => {
    return (
      <>
        <div className="dome-h3 dome-color-dark-green">insurance quote</div>
        <QuoteTabsProgress activeTab={tabs[step]} />
        <div className="insurance-quote-property-property-headline">
          Get an instant estimate based on your property’s requirements.
        </div>
        <BuildingSearch
          show_empty_message={true}
          show_magnifying_glass={true}
          url={SUGGESTIONS_URL}
          classes={{
            iconClass: 'fa-light fa-magnifying-glass search-icon',
            containerClass: 'search-container',
            inputClass: 'search-container-search',
            searchBarBorderClass: 'searchbar-border-sm',
          }}
          onSelect={onBuildingSelect}
        />
      </>
    )
  }

  const renderPolicyMain = () => {
    const onBack = (data) => {
      dispatch(setFormDataAction({ data, step: 'policy' }))
      dispatch(setStepAction(INSURANCE_QUOTE_STEPS.property_page))
    }

    const onNext = (data) => {
      dispatch(setFormDataAction({ data, step: 'policy' }))

      isEmpty(quote)
        ? dispatch(
            initiateQuoteAction({
              rollbackStep: INSURANCE_QUOTE_STEPS.policy_page,
              nextStep: INSURANCE_QUOTE_STEPS.about_you_page,
            })
          )
        : dispatch(
            initiateQuoteRequestAction({
              currentStep: INSURANCE_QUOTE_STEPS.policy_page,
              nextStep: INSURANCE_QUOTE_STEPS.about_you_page,
            })
          )
    }

    const formDefaultValues = (() => {
      const {
        premium,
        writingCompany,
        liabilityLimit,
        homeAndContentsDeductible,
        contentsAmount,
        additionsAndAlterationsAmount,
      } = isEmpty(quote) && startingQuote ? startingQuote : quote

      return {
        personal_liability: liabilityLimit,
        deductible_amount: homeAndContentsDeductible,
        content_coverage: contentsAmount,
      }
    })()

    // TODO: Handle requirementsLoading
    const formOptions = {
      occupancyTypes: insuranceConstants.OCCUPANCY_TYPES || [],
      personalLiabilityLimits: valuesLargerThan(
        asDollars(minimums[insuranceType]?.personal_liability),
        insuranceConstants.LIABILITY_LIMITS
      ),
      deductibleAmounts: insuranceConstants.CONTENTS_DEDUCTABLES || [],
      minimumContentAmount: asDollars(minimums[insuranceType]?.contents_coverage),
      minimumDwellingAmount: asDollars(minimums[insuranceType]?.betterments_additions_alterations),
      required: requirements[`requires_${insuranceType}`],
      defaultValues: formDefaultValues,
    }

    const canGoBack = !package_id

    return (
      <>
        <div className="dome-h3 dome-color-dark-green">insurance quote</div>
        <QuoteTabsProgress activeTab={tabs[step]} />
        <PolicyForm
          onBack={onBack}
          onNext={onNext}
          formData={formData}
          building={building}
          building_structure_id={building_structure_id}
          insuranceType={insuranceType}
          formOptions={formOptions}
          canGoBack={canGoBack}
        />
      </>
    )
  }

  const renderAboutYouMain = () => {
    const onBack = (data) => {
      dispatch(setFormDataAction({ data, step: 'about_you' }))
      dispatch(setStepAction(INSURANCE_QUOTE_STEPS.policy_page))
    }

    const onNext = (data) => {
      dispatch(setFormDataAction({ data, step: 'about_you' }))

      isEmpty(quote)
        ? dispatch(
            initiateQuoteAction({
              rollbackStep: INSURANCE_QUOTE_STEPS.about_you_page,
              nextStep: INSURANCE_QUOTE_STEPS.finalize_page,
            })
          )
        : dispatch(
            initiateQuoteRequestAction({
              currentStep: INSURANCE_QUOTE_STEPS.about_you_page,
              nextStep: INSURANCE_QUOTE_STEPS.finalize_page,
            })
          )
    }

    const formOptions = {
      insuredTypes: Object.keys(insuranceConstants.INSURED_TYPES).map((key) => ({
        key: key,
        value: insuranceConstants.INSURED_TYPES[key]['display'],
      })),
      occupations: insuranceConstants.OCCUPATION_TYPES,
      carrierNames: insuranceConstants.PRIOR_CARRIERS,
    }

    return (
      <>
        <div className="dome-h3 dome-color-dark-green">insurance quote</div>
        <QuoteTabsProgress activeTab={tabs[step]} />
        <AboutYouForm onBack={onBack} onNext={onNext} formData={formData} formOptions={formOptions} />
      </>
    )
  }

  const renderFinalizeQuoteMain = () => {
    const onBack = (data) => {
      dispatch(setFormDataAction({ data, step: 'finalize' }))
      dispatch(setStepAction(INSURANCE_QUOTE_STEPS.about_you_page))
    }

    return (
      <>
        <div className="finalize-quote-container">
          <div className="dome-h3">finalize your quote</div>
          <FinalizeQuoteForm onBack={onBack} />
        </div>
      </>
    )
  }

  const renderMainContent = () => {
    switch (step) {
      case INSURANCE_QUOTE_STEPS.property_page:
        return renderPropertyMain()
      case INSURANCE_QUOTE_STEPS.policy_page:
        return renderPolicyMain()
      case INSURANCE_QUOTE_STEPS.about_you_page:
        return renderAboutYouMain()
      case INSURANCE_QUOTE_STEPS.finalize_page:
        return renderFinalizeQuoteMain()
      default:
        return null
    }
  }
  // ----------

  return (
    <div className={clsx('insurance-quote-interstitial__content', loadingVisible && 'dome-overflow-hidden')}>
      <div className="insurance-quote-interstitial__content-wrapper">
        <div className="insurance-quote-interstitial__content-content">{renderMainContent()}</div>
      </div>
    </div>
  )
}

Main.defaultProps = {
  loadingVisible: false,
}

export default memo(Main)
