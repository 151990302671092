import React, { useEffect, useMemo, useState } from 'react'
import { Provider, useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'

import BuildingSearch from 'components/utility/building_search'
import viaffinityLogo from '/app/assets/images/insurance/logos/viafinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import loadingIcon from '/app/assets/images/insurance/icons/loader.gif'
import GetQuoteButton from '../button'
import { selectInsuranceQuoteInterstitialState, setCurrentUser, setStartingQuote } from '../store/slice'
import { store } from '../store/store'
import Switch from 'components/utility/switch'
import { formattedMoney } from '../utils'
import { useGetStartingQuote } from '../hooks'
import MonetaryAmount from '../components/MonetaryAmount'

const SUGGESTIONS_URL = '/insurance/buildings/lookup_buildings'

const STEPS = { search: 'search', selected: 'selected' }

const ServerError = () => {
  return (
    <div className="quote-server-error dome-bg-fill">
      <div className="dome-p2 dome-color-dark-grey">The Chubb rating service is unavailable.</div>
      <div className="dome-p3-lt dome-color-dark-grey" style={{ fontSize: '10px' }}>
        If this problem persists, please contact{' '}
        <a href="mailto:info@domecile.com" className="dome-link dome-link-aqua" style={{ fontSize: '10px' }}>
          info@domecile.com
        </a>
        .
      </div>
    </div>
  )
}

const QuoteLoading = () => {
  return (
    <div className="quote-loading dome-d-flex dome-align-center">
      <div className="dome-loading-img">
        <img src={loadingIcon} width={95} height={95} />
      </div>
      <div>
        <div className="dome-p2 dome-color-dark-grey">We’re still working on the estimate for this property.</div>
        <div className="dome-p3-lt dome-color-dark-grey">In the meantime, continue to the next step.</div>
      </div>
    </div>
  )
}

const QuoteResult = ({ premium, insuranceType, all_requirements }) => {
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const required = (all_requirements || {})[`requires_${insuranceType}`] || false

  return (
    <div className="dome-d-flex dome-justify-between dome-align-center">
      <div className="dome-p1">
        {required ? 'estimate based on requirements' : "estimate based on insurer's recommendations"}
      </div>
      <div className="dome-p1 dome-d-flex dome-align-baseline">
        <div className="dome-h2 dome-color-dark-green dome-d-flex dome-align-start" style={{lineHeight: 1}}>
          <MonetaryAmount amount={monthlyAmount} />
        </div>
        <span>/month</span>
      </div>
    </div>
  )
}

function EstimatedQuoteResult({ building, onBack, active }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [started, setStarted] = useState(false)
  const [insuranceType, setInsuranceType] = useState('homeowners')

  useEffect(() => {
    if (!active) setInsuranceType('homeowners')
  }, [active])

  const { quote, fullScreenLoading, serverError } = useSelector(selectInsuranceQuoteInterstitialState)
  const startingQuote = useGetStartingQuote(building, insuranceType)

  const [premium, writingCompany] = useMemo(() => {
    if (quote && quote.insuranceType === insuranceType && quote.buildingId === building?.id && quote.premium) {
      return [quote.premium, quote.writingCompany]
    }

    if (startingQuote) {
      return [startingQuote.premium, startingQuote.writingCompany]
    }

    return [null, null]
  }, [quote, insuranceType, startingQuote, building?.id])

  useEffect(() => {
    if (fullScreenLoading.visible) {
      setLoading(fullScreenLoading.visible)
    }

    if (!fullScreenLoading.visible && started) {
      setLoading(false)
    }
  }, [fullScreenLoading])

  useEffect(() => {
    if (building?.id && !premium) setStarted(true)
  }, [building?.id])

  useEffect(() => {
    dispatch(setStartingQuote(isEmpty(startingQuote) ? null : startingQuote))
  }, [dispatch, startingQuote])

  const goBack = () => {
    onBack()
    setStarted(false)
  }

  const onInterstitialOpen = () => setStarted(true)
  const onInterstitialClose = () => {
    goBack()
  }

  const renderQuoteMain = () => {
    if (serverError) {
      return <ServerError />
    }

    if (loading || !premium) {
      return <QuoteLoading />
    }

    return (
      <QuoteResult
        premium={premium}
        insuranceType={insuranceType}
        all_requirements={building.parsed_all_requirements}
      />
    )
  }

  const handleToggleInsuranceType = (on) => {
    setInsuranceType(on ? 'renters' : 'homeowners')
  }

  return (
    <>
      <div className="estimated-result dome-d-flex dome-justify-between">
        <div className="dome-h2 dome-color-darkest-grey">{building?.title}</div>
        {active && <Switch offText="owner" onText="renter" onChange={handleToggleInsuranceType} />}
      </div>
      <div>
        <button className="dome-btn dome-btn-link dome-btn-link-chevron-left dome-btn-link-aqua" onClick={goBack}>
          edit
        </button>
      </div>
      <hr />
      <div className="estimated-quote-result">{renderQuoteMain()}</div>
      <div className='get-quote-container'>
        <div className="dome-d-flex dome-justify-between dome-align-center">
          <div className="quote-logos">
            <div>
              <em>by</em>
              <img src={viaffinityLogo} style={{ width: '56px' }} />
            </div>
            <div style={{borderTop: '1px solid var(--disabled)'}}>
              <em>from</em>
              <img src={chubbLogo} style={{ width: '60px' }} />
            </div>
          </div>
          <GetQuoteButton
            building={building}
            started={started}
            onInterstitialOpen={onInterstitialOpen}
            onInterstitialClose={onInterstitialClose}
            insuranceType={insuranceType}
          />
        </div>
      </div>
      {writingCompany && (
        <div className="insurance-written-by dome-color-med-grey">Insurance underwritten by: {writingCompany}</div>
      )}
    </>
  )
}

function InstantQuoteLookup({ building: defaultBuilding, currentUser, visible }) {
  const [currentBuilding, setCurrentBuilding] = useState(defaultBuilding)
  const [step, setStep] = useState(STEPS.search)

  const dispatch = useDispatch()

  const onBuildingSelect = (building) => {
    if (!building?.id) return

    setCurrentBuilding(building)
    setStep(STEPS.selected)
  }

  const onBack = () => {
    setCurrentBuilding(null)
    setStep(STEPS.search)
  }

  useEffect(() => {
    dispatch(setCurrentUser(currentUser))
  }, [])

  useEffect(() => {
    if (!visible) {
      onBack()
    }
  }, [visible])

  return (
    <div className="instant-quote-lookup">
      <div className={clsx('instant-quote-lookup__step', step === STEPS.search && 'active')}>
        <div className='dome-d-flex dome-flex-column dome-gap3 lookup-logos'>
          <div className='dome-d-flex dome-align-center dome-gap3'>
            <em>by</em>
            <img src={viaffinityLogo} style={{ width: '60px' }} />
          </div>
          <div className='dome-d-flex dome-align-center dome-gap3'>
            <em>from</em>
            <img src={chubbLogo} style={{ width: '63px' }} />
          </div>
        </div>
        <div className="instant-quote-header dome-h3 dome-color-green-gradient">
          insurance for your home
        </div>
        <div className="search-wrapper dome-d-flex dome-flex-column">
          <div className="search-wrapper-text dome-p3" style={{fontSize: '12px'}}>
            <div className='dome-d-flex dome-align-center dome-gap6'>
              <i className='far fa-check dome-color-sage'  />
              <span>matches building requirements</span>
            </div>
            <div className='dome-d-flex dome-align-center dome-gap6'>
              <i className='far fa-check dome-color-sage' />
              <span>no personal info needed for estimate</span>
            </div>
            <div className='dome-d-flex dome-align-center dome-gap6'>
              <i className='far fa-check dome-color-sage' />
              <span>policy auto uploads to library </span>
            </div>
          </div>
          <BuildingSearch
            show_empty_message={true}
            show_magnifying_glass={true}
            url={SUGGESTIONS_URL}
            classes={{
              iconClass: 'fa-light fa-magnifying-glass search-icon',
              containerClass: 'search-container',
              inputClass: 'search-container-search',
              searchBarBorderClass: 'searchbar-border-sm',
            }}
            placeholderText="find your property's estimate"
            onSelect={onBuildingSelect}
            customEmptyMessage={
              <>
                <div>
                  It looks like insurance is not available for this property yet. For more information,{' '}
                  <a
                    href="https://boardpackager.freshdesk.com/support/tickets/new"
                    target="_blank"
                    className="dome-link dome-link-aqua"
                  >
                    contact us.
                  </a>
                </div>
              </>
            }
          />
        </div>
      </div>
      <div className={clsx('instant-quote-lookup__step', step === STEPS.selected && 'active')}>
        <EstimatedQuoteResult building={currentBuilding} onBack={onBack} active={step === STEPS.selected} />
      </div>
    </div>
  )
}

InstantQuoteLookup.defaultProps = {
  visible: true,
}

export default (props) => (
  <Provider store={store}>
    <InstantQuoteLookup {...props} />
  </Provider>
)
