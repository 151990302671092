import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { INSURANCE_QUOTE_STEPS, selectCurrentBuildingStructure, selectInsuranceQuoteInterstitialState } from '../../store/slice'
import AboutYouSidebar from './AboutYou/AboutYouSidebar'
import PropertySidebar from './PropertySidebar'
import FinalizeQuoteSidebar from './FinalizeQuote/FinalizeQuoteSidebar'

const Sidebar = ({ step, loadingVisible }) => {
  const { quote, insuranceType, building, formData, insuranceConstants, startingQuote } = useSelector(
    selectInsuranceQuoteInterstitialState
  )

  const buildingStructure = useSelector(selectCurrentBuildingStructure)

  const sidebarDetails = useMemo(() => {
    return {
      title: building?.title,
      address: buildingStructure?.default_additional_address?.address,
      city: building?.city,
      state: building?.state,
      zip: building?.zip,
      buildingTypeDisplay: insuranceConstants?.BUILDING_TYPES_DISPLAY
        ? insuranceConstants?.BUILDING_TYPES_DISPLAY[building?.building_type]
        : null,
      buildingUnit: formData.building_unit,
      squareFootage: formData.square_footage,
      firstName: formData.first_name,
      middleName: formData.middle_name,
      lastName: formData.last_name,
      phoneNumber: formData.phone_number,
      email: formData.email,
      occupancy: formData.occupancy,
    }
  }, [building, formData, insuranceConstants, buildingStructure])

  const renderPropertySidebar = () => {
    return <PropertySidebar />
  }

  const renderPolicySidebar = () => {
    return <AboutYouSidebar quote={quote} insuranceType={insuranceType} sidebarDetails={sidebarDetails} isPolicy startingQuote={startingQuote} formData={formData} />
  }

  const renderAboutYouSidebar = () => {
    return <AboutYouSidebar quote={quote} insuranceType={insuranceType} sidebarDetails={sidebarDetails} startingQuote={startingQuote} formData={formData} />
  }

  const renderFinalizeQuoteSidebar = () => {
    return <FinalizeQuoteSidebar quote={quote} insuranceType={insuranceType} sidebarDetails={sidebarDetails} />
  }

  // TODO: Refactor
  const renderSidebarContent = () => {
    switch (step) {
      case INSURANCE_QUOTE_STEPS.property_page:
        return renderPropertySidebar()
      case INSURANCE_QUOTE_STEPS.policy_page:
        return renderPolicySidebar()
      case INSURANCE_QUOTE_STEPS.about_you_page:
        return renderAboutYouSidebar()
      case INSURANCE_QUOTE_STEPS.finalize_page:
        return renderFinalizeQuoteSidebar()
      default:
        return null
    }
  }

  return (
    <div className={clsx('insurance-quote-interstitial__sidebar', loadingVisible && 'dome-overflow-hidden')}>
      <div className="insurance-quote-interstitial__sidebar-wrapper">
        <div className="insurance-quote-interstitial__sidebar-content">{renderSidebarContent()}</div>
      </div>
    </div>
  )
}

Sidebar.defaultProps = {
  loadingVisible: false,
}

export default memo(Sidebar)
